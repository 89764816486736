import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { AreaWithStations, Location, MyHttpApi, PaymentTerminal, PaymentTerminalClientUpdateRequest, Station } from 'utils/api';
import { clientIdToTypeAndName, getSelectedClient, privilegeItemFromElement } from 'utils/corporation-util';

interface UIAreaWithStations extends AreaWithStations {
  checked: boolean;
  disabled: boolean;
  stations: UIStation[];
}

interface UIStation extends Station {
  checked: boolean;
  disabled: boolean;
}

@autoinject
export class PosPaymentTerminalClientEdit {
  private paymentTerminalClient: PaymentTerminalClientUpdateRequest = {
    locationId: "",
    name: "",
    integrationState: "ECR",
    areaIds: [],
    stationIds: [],
    type: "CLIENT",
    delete: false,
    extra: false,
		requireCardProcessingType: false,
    isVivaTerminalActivated: false,
    vivaWalletTerminalForceReset: false,
  };
  private clientId?: number;
  private typeAndName = "";
  private paymentTerminalList: PaymentTerminal[] = [];
  private selectedPaymentTerminal?: PaymentTerminal = undefined;
  private locationList: Location[] = [];
  private areaList: UIAreaWithStations[] = [];
  private selectedTerminalString?: string = undefined;

  constructor(private api: MyHttpApi, private router: Router, private engine: BindingEngine, private i18n: I18N) {
  }

  async activate(params: { id?: string; clientId?: number }) {
    if (params.id) {
      let paymentTerminalClient = await this.api.paymentTerminalClientWithAreasById({ id: params.id });
      this.paymentTerminalClient = {
        ...paymentTerminalClient.paymentTerminalClient,
        areaIds: paymentTerminalClient.areaIds,
        stationIds: paymentTerminalClient.stationIds,
        delete: !!paymentTerminalClient.paymentTerminalClient.deleteTime,
        type: 'CLIENT'
      };
      this.clientId = privilegeItemFromElement(paymentTerminalClient.paymentTerminalClient)?.id;
    }
    if (!params.id) {
      this.clientId = getSelectedClient() || params.clientId;
    }

    this.typeAndName = await clientIdToTypeAndName(this.api, this.i18n, this.clientId);

    [this.paymentTerminalList, this.locationList] = await Promise.all([
      this.api.paymentTerminalList({ all: false }),
      this.api.locationList({ id: this.clientId }),
      this.updateAreaList(),
    ]);
    const terminal = this.paymentTerminalList.find(x => x.id === this.paymentTerminalClient.paymentTerminalId);
    if (terminal) {
      this.selectedTerminalString = terminal.serialNumber + " / " + terminal.id;
      this.selectedPaymentTerminal = terminal;
    }
  }

  async updateAreaList() {
    if (!this.paymentTerminalClient.locationId) {
      return;
    }
    const areaList = await this.api.areaListWithStationsByLocationId({ locationId: this.paymentTerminalClient.locationId });
    this.areaList = areaList.map(a => ({
      ...a,
      checked: this.paymentTerminalClient.areaIds.indexOf(a.area.id) != -1,
      disabled: false,
      stations: a.stations.map(s => ({
        ...s,
        checked: this.paymentTerminalClient.stationIds.indexOf(s.id) != -1,
        disabled: false,
      })),
    }));

    // Add observer to 'checked' property of all areas and stations
    this.updateAreaSelectionDisabling();
    this.stationObserver();
    this.areaList.forEach(x => this.engine.propertyObserver(x, "checked").subscribe(() => this.updateAreaSelectionDisabling()));
    this.areaList.flatMap(x => x.stations).forEach(x => this.engine.propertyObserver(x, "checked").subscribe(() => this.stationObserver()));
  }

  async save(deleted: boolean) {
    if (!this.clientId) {
      return;
    }
    await this.api.paymentTerminalClientUpdate({
      ...this.paymentTerminalClient,
      areaIds: this.areaList.filter(x => x.checked && !x.disabled).map(x => x.area.id),
      stationIds: this.areaList.flatMap(x => x.stations).filter(x => x.checked && !x.disabled).map(x => x.id),
      privilegeId: this.clientId,
      type: "CLIENT",
      delete: deleted,
    });
    this.router.navigateBack();
  }

  @computedFrom("paymentTerminalList")
  get searchablePaymentTerminalList() {
    return this.paymentTerminalList.map(x => x.serialNumber + " / " + x.id);
  }

  setTerminal(idAndserial: string) {
    let parts = idAndserial.split(" / ");
    const id = parseInt(parts[parts.length - 1]);
    let terminal = this.paymentTerminalList.find(x => x.id == id);
    this.selectedPaymentTerminal = terminal;
    this.paymentTerminalClient.paymentTerminalId = terminal?.id;
    return terminal;
  }

  @computedFrom("paymentTerminalClient.locationId")
  get locationObserver() {
    void this.updateAreaList();
    return "";
  }

  @computedFrom("paymentTerminalClient.integrationState")
  get integrationStateObserver() {
    this.updateAreaSelectionDisabling();
    return "";
  }

  updateAreaSelectionDisabling() {
    const isTables = this.paymentTerminalClient.integrationState == "TABLES";
    // Terminals with TABLES integration state can have only one area and one station selected
    const selectedArea = this.areaList.find(x => x.checked);
    this.areaList.forEach(a => {
      a.disabled = isTables && !!selectedArea && a.area != selectedArea.area;
      a.checked = a.disabled ? false : a.checked; // Uncheck disabled
      // Deselect stations with unselected area
      if (!a.checked) {
        a.stations.forEach(station => station.checked = false);
      }
      const selectedStation = a.stations.find(x => x.checked);
      a.stations.forEach(s => {
        s.disabled = isTables && (a.disabled || !!selectedStation && s != selectedStation);
        s.checked = s.disabled ? false : s.checked; // Uncheck disabled
      });
    });
  }

  stationObserver() {
    // Select areas with selected stations
    this.areaList.forEach(area => {
      const hasStationsSelected = area.stations.some(station => station.checked);
      if (hasStationsSelected) {
        area.checked = true;
      }
    });

    this.updateAreaSelectionDisabling();
  }

  get isVivaWalletPaymentTerminal() {
    return this.selectedPaymentTerminal?.integrationPartner == "VIVA_WALLET";
  }

  isVivaTerminalActivatedOnChange(value: boolean) {
	if (!value) {
		const newValue = !confirm(this.i18n.tr("payment-terminal.forceActivationConfirm"));
		this.paymentTerminalClient.isVivaTerminalActivated = newValue;
	}
  }

  vivaWalletTerminalForceActivationAndResetOnChange(value: boolean) {
	if (value) {
		const newValue = confirm(this.i18n.tr("payment-terminal.forceActivationAndResetConfirm"));
		this.paymentTerminalClient.vivaWalletTerminalForceReset = newValue;
	}
  }

  get integrationStates() {
    const states = [
      { id: "ECR", name: this.i18n.tr("PaymentTerminalIntegrationState.ECR") },
      { id: "TABLES", name: this.i18n.tr("PaymentTerminalIntegrationState.TABLES") },
    ];
    if (this.isVivaWalletPaymentTerminal) {
      states.push({ id: "CLOUD", name: this.i18n.tr("PaymentTerminalIntegrationState.CLOUD") });
    } else if (!this.isVivaWalletPaymentTerminal && this.paymentTerminalClient.integrationState == 'CLOUD') {
      this.paymentTerminalClient.integrationState = "ECR";
    }
    return states;
  }
}
