import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Area, IdName, Location, MyHttpApi, PortionSize, PricingGroup, PricingGroupRow, PricingGroupUpdateRequest, PrivilegeItem, ProductCategory, ProductSubCategory, SalesChannel, SalesRestriction } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

export interface UIPricingGroupRow {
  clientId?: number;
  portionSizeId?: string;
  salesChannelId?: string;
  salesRestrictionId?: string;
  locationId?: string;
  areaId?: string;
  pricingGroupId?: string;
  isHidden: boolean;
  extraPortionSize?: PortionSize;
  extraSalesChannel?: SalesChannel;
  extraSalesRestriction?: SalesRestriction;
}

export class UIPricingGroupRowClass {
  @computedFrom("row.locationId")
  get areaListDynamic() {
    if (!this.row.locationId) {
      this.row.areaId = undefined;
      return [];
    }
    return this.areaList.filter(a => a.locationId === this.row.locationId);
  }

  constructor(public row: UIPricingGroupRow, public areaList: Area[]) { }
}

@autoinject
export class PosPricingGroupEdit {
  private pricingGroup: PricingGroupUpdateRequest = {
    name: "",
    type: "CLIENT",
    delete: false,
    isDefault: false,
    rows: [],
  };
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private typeAndName = "";
  private productCategoryList: ProductCategory[] = [];
  private productSubCategoryList: ProductSubCategory[] = [];
  private portionSizeList: PortionSize[] = [];
  private salesChannelList: SalesChannel[] = [];
  private salesRestrictionList: SalesRestriction[] = [];
  private pricingGroupList: PricingGroup[] = [];
  private extraProductCategory?: ProductCategory;
  private extraProductSubCategory?: ProductSubCategory;
  private locationList: Location[] = [];
  private areaList: Area[] = [];
  private clientList: IdName[] = [];
  private selectedClientId?: number;
  private rows: UIPricingGroupRowClass[] = [];
  private canEdit = false;
  private canSpecify = false;
  private showLocationAndArea = true;

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private notify: Notify) {
  }

  async activate(params: { id?: string, isMaster?: string; }) {
    this.isMaster = params.isMaster === "true";
    await this.reload(params.id);
  }

  async reload(id?: string) {
    if (!this.isMaster && !id) {
      this.privilege = getSelectedCorporation();
    } else if (this.isMaster) {
      this.privilege = undefined;
    }

    // * We need to fetch pricingGroup first, as we will anyway need privilegeId from it
    let rows: PricingGroupRow[] = [];
    if (id) {
      let [pricingGroup, rowsTmp] = await Promise.all([
        this.api.pricingGroupById({ id }),
        this.api.pricingGroupListRows({ id }),
      ]);
      this.pricingGroup = { ...pricingGroup, delete: !!pricingGroup.deleteTime, type: 'CLIENT', rows: [] };
      this.privilege = privilegeItemFromElement(pricingGroup);
      rows = rowsTmp;
    }

    if (this.privilege) {
      this.canEdit = await this.api.privilegeCanEdit(this.privilege);
      if (this.privilege.id && this.privilege.type === 'CLIENT') {
        const bgr = await this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id });
        this.canEdit = bgr.clientSpecificPricingGroups == 'ALLOW';
      }
      if (this.privilege.type == 'BUSINESS_GROUP' && this.privilege.id) {
        const [clientList, bg] = await Promise.all([
          this.api.clientListByBusinessGroupId({id: this.privilege.id}),
          this.api.businessGroupById({ id: this.privilege.id })
        ]);
        this.clientList = clientList;
        this.canSpecify = bg.clientSpecificPricingGroups != 'RESTRICT';
      }
    }

    if (this.showLocationAndArea && this.privilege?.id) {
      const [locationList, areaList] = await Promise.all([
        this.api.locationList({ id: this.privilege.id }),
        this.api.areaList({ id: this.privilege.id }),
      ]);
      this.locationList = locationList.filter(x => !x.deleteTime);
      this.areaList = areaList.filter(x => !x.deleteTime);
    }
    const [productCategoryList, productSubCategoryList, portionSizeList, salesChannelList, salesRestrictionList, pricingGroupList] = await Promise.all([
      this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
      this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
      this.api.portionSizeList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
      this.api.salesChannelList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
      this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
      this.api.pricingGroupList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
    ]);
    this.productCategoryList = productCategoryList.filter(x => !x.deleteTime);
    this.productSubCategoryList = productSubCategoryList.filter(x => !x.deleteTime);
    this.portionSizeList = portionSizeList.filter(x => !x.deleteTime);
    this.salesChannelList = salesChannelList.filter(x => !x.deleteTime);
    this.salesRestrictionList = salesRestrictionList.filter(x => !x.deleteTime);
    this.pricingGroupList = pricingGroupList.filter(x => !x.deleteTime);

    if (this.pricingGroup.id) {
      this.rows = rows.map(r => {
        const row = {
          ...r,
          extraPortionSize: portionSizeList.find(x => x.id === r.portionSizeId),
          extraSalesChannel: salesChannelList.find(x => x.id === r.salesChannelId),
          extraSalesRestriction: salesRestrictionList.find(x => x.id === r.salesRestrictionId),
        };
        return new UIPricingGroupRowClass(row, this.areaList);
      });
      this.extraProductCategory = productCategoryList.find(x => x.id === this.pricingGroup.productCategoryId);
      this.extraProductSubCategory = productSubCategoryList.find(x => x.id === this.pricingGroup.productSubCategoryId);
    }

    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
    if (this.privilege) {
      this.canEdit = await this.api.privilegeCanEdit(this.privilege);
      if (this.privilege.id && this.privilege.type === 'CLIENT') {
        const bgr = await this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id });
        this.canEdit = bgr.clientSpecificPricingGroups == 'ALLOW';
      }
      if (this.privilege.type == 'BUSINESS_GROUP' && this.privilege.id) {
        const bg = await this.api.businessGroupById({ id: this.privilege.id });
        this.canSpecify = bg.clientSpecificPricingGroups != 'RESTRICT';
      }
    }
  }

  get disabledText() {
    let text = "";
    if (!this.canEdit) {
      text = this.i18n.tr('businessGroupRestriction.readOnly');
      if (this.canSpecify) {
        text += ` ${this.i18n.tr("businessGroupRestriction.canSpecify")}`;
      }
    }
    return text;
  }

  @computedFrom("clientList", "rows.length")
  get specifiedClientList() {
    // workaround to get client list sorted according to the reversed appearance in rows
    const clientIds = [...new Set(this.rows.map(r => r.row.clientId))];
    let clientList: IdName[] = [];
    let clientMap = MyHttpApi.toHash(this.clientList);
    clientIds.forEach(cId => {
      if (cId) {
        clientList.push(clientMap[cId]);
      }
    });
    return clientList.reverse();
  }

  @computedFrom("clientList", "rows.length")
  get unspecifiedClientList() {
    return this.clientList.filter(c => !this.rows.find(r => r.row.clientId === c.id));
  }

  specify() {
    if (this.selectedClientId) {
      this.addRow(this.selectedClientId);
      this.selectedClientId = undefined;
    }
  }

  /** Check, if we have any default with these groups already defined and warn of overwriting if so */
  @computedFrom('pricingGroupList', 'pricingGroup.id', 'pricingGroup.isDefault', 'pricingGroup.productCategoryId', 'pricingGroup.productSubCategoryId')
  get defaultCrossCheckWhine() {
    let whine = false;
    if (this.pricingGroup.isDefault) {
      let privilegeType = this.privilege?.type || "CLIENT";
      let privilegeId = this.privilege?.id || 0;

      let oldDefault = this.pricingGroupList.find(x =>
        x.id !== this.pricingGroup.id &&
        x.isDefault &&
        (
          privilegeType == 'CLIENT' && privilegeId === x.clientId ||
          privilegeType == 'BUSINESS_GROUP' && privilegeId === x.businessGroupId ||
          privilegeType == 'CORPORATION' && privilegeId === x.corporationId
        ) &&
        x.productCategoryId == this.pricingGroup.productCategoryId &&
        x.productSubCategoryId == this.pricingGroup.productSubCategoryId);

      if (oldDefault) {
        whine = true;
      }
    }
    return whine;
  }

  async save(deleted: boolean) {
    await this.api.pricingGroupUpdate({
      ...this.pricingGroup,
      rows: this.rows.map(x => {
        return {
          // * pricingGroupId and id will be overwritten in serverSide
          id: "",
          pricingGroupId: "",
          portionSizeId: x.row.portionSizeId || "",
          salesChannelId: x.row.salesChannelId,
          salesRestrictionId: x.row.salesRestrictionId,
          locationId: x.row.locationId,
          areaId: x.row.areaId,
          isHidden: x.row.isHidden,
        };
      }),
      privilegeId: this.privilege?.id,
      type: this.privilege?.type || 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }

  addRow(clientId?: number) {
    let item = new UIPricingGroupRowClass({ clientId, isHidden: false }, this.areaList);
    this.rows.push(item);
    setTimeout(() => {
      let rowElements = document.querySelectorAll("form pricing-group-rows div");
      console.log("rowElements[1]", rowElements[1]);
      rowElements[1]?.classList.add("fade-in");

    }, 500);
  }

  deleteRow(x: UIPricingGroupRowClass) {
    this.rows = this.rows.filter(srt => srt !== x);
  }

  @computedFrom("pricingGroup.productCategoryId")
  get productSubCategoryListDynamic() {
    const id = this.pricingGroup.productCategoryId;
    if (!id) {
      return [];
    }
    return this.productSubCategoryList.filter(psc => psc.productCategoryId === id);
  }
}
