import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, MyHttpApi, PrivilegeItem, Product, ProductType } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedClient, getSelectedCorporation } from 'utils/corporation-util';
import { I18N } from 'aurelia-i18n';
import { Notify } from 'utils/notify';

@autoinject
export class PosProductList {
  private fieldList: FieldSpec[] = [
    { key: "name", header: "common.name", type: "text", },
    { key: "amount", header: "common.value", type: "number", },
    { key: "isStorageSize", header: "portionSize.isStorageSize", type: "boolean", },
  ];
  private itemList: Product[] = [];
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private productType?: ProductType = undefined;
  private businessGroup?: BusinessGroup;

  constructor(private notify: Notify, private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: { isMaster: string; productType: ProductType }) {
	this.productType = params.productType as ProductType | undefined;
    let {clients, businessGroups, corporations} = await getAllAccessLevelsViaClient(this.api);
    this.isMaster = params.isMaster === "true";
    if (!this.isMaster) {
      this.privilege = getSelectedCorporation();
      if(this.privilege?.businessGroupId) {
		this.businessGroup = businessGroups[this.privilege?.businessGroupId];
      } else if(this.privilege?.type == "CLIENT" && this.privilege?.id) {
		let client = clients[this.privilege?.id];
		if(client.businessGroupId) {
			this.businessGroup = businessGroups[client.businessGroupId]
		}
      }

      this.fieldList = [...this.fieldList,
        { key: "corporationId", header: "corporation.corporation", type: "lookup", lookupData: corporations, lookupKey: "name", },
        { key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
        { key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
      ];
    }
  }

  async edit(key: string, item?: Product) {
	if(getSelectedClient() && !this.businessGroup?.clientSpecificProducts) {
		let itemType: "REGULAR" | "ALIAS" | "RECIPE" = item?.type ?? this.productType ?? "REGULAR";
		this.notify.info("businessGroupRestriction.notAllowed", {element: this.i18n.tr(`businessGroupRestriction.${itemType}`)});
	} else if(item?.id) {
			this.router.navigateToRoute(this.editUrl, {id:  item.id, isMaster: this.isMaster });
	} else {
			this.router.navigateToRoute(this.editUrl, { isMaster: this.isMaster });
	}
  }

  async search() {
    if (!this.isMaster && !this.privilege) {
      return;
    }
    const itemList = await this.api.productList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
    this.itemList = itemList.filter(x => !x.deleteTime && x.type === this.productType);
  }

  get productTypeTranslation() {
	return this.i18n.tr(`ProductType.${(this.productType || "REGULAR")}`).toLowerCase();
  }

  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("privilege.id")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }
}
