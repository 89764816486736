import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, PrivilegeItem, ProductCategoryUpdateRequest, StorageUnit, VatCategory } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';

@autoinject
export class PosProductCategoryEdit {
  private productCategory: ProductCategoryUpdateRequest = {
    name: "",
    defaultBaseUnit: "PCS",
    vatCategoryId: 0,
    type: "CLIENT",
    delete: false,
    nonOperativeSales: false,
  };
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private storageUnitList: StorageUnit[] = [];
  private extra?: StorageUnit;
  private vatCategoryList: VatCategory[] = [];
  private typeAndName = "";
  private disabled = false;

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: { id?: string, isMaster?: string; }) {
    this.isMaster = params.isMaster === "true";
    if (params.id) {
      let productCategory = await this.api.productCategoryById({ id: params.id });
      this.productCategory = { ...productCategory, delete: !!productCategory.deleteTime, type: 'CLIENT' };
      this.privilege = privilegeItemFromElement(productCategory);
      if (productCategory.defaultStorageUnitId) {
        this.extra = await this.api.storageUnitById({ id: productCategory.defaultStorageUnitId });
      }
    }
    if (!this.isMaster && !params.id) {
      this.privilege = getSelectedCorporation();
    } else if (this.isMaster) {
      this.privilege = undefined;
    }
    [this.vatCategoryList, this.storageUnitList] = await Promise.all([
      this.api.vatCategoryList(),
      this.api.storageUnitList({ type: this.privilege?.type || 'CLIENT', id: this.privilege?.id }),
    ]);

    if (this.privilege) {
      let canEdit = await this.api.privilegeCanEdit(this.privilege);
      if (this.privilege.id && this.privilege.type === 'CLIENT') {
        const bgr = await this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id });
        canEdit = bgr.clientSpecificCategories;
      }
      this.disabled = !canEdit;
    }

    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
  }

  get disabledText() {
    if (this.disabled) {
      return this.i18n.tr('businessGroupRestriction.readOnly');
    }
    return "";
  }

  async save(deleted: boolean) {
    await this.api.productCategoryUpdate({
      ...this.productCategory,
      privilegeId: this.privilege?.id,
      type: this.privilege?.type || 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }

}
