import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Area, Location, MyHttpApi, PortionSize, PricingGroup, PricingGroupResponse, PrivilegeItem, ProductCategory, ProductSubCategory, ProductUpdateRequest, ProductUpdateRequestPG, SalesChannel, SalesRestriction, StorageUnit, VatCategoryWithRows } from 'utils/api';
import { getPrivilege, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';
import { Notify } from 'utils/notify';
import { sameLocalDateAtMidnightUtc } from 'utils/date-util';

const toHashStr = MyHttpApi.toHashStr;

class UIPricingGroup {
	get zeroVatPrice() {
		if (this.productUpdateRequestPg.price == undefined) {
			return undefined;
		}
		let factor = 1 + (this.vatPercent || 0) / 100;
		return Math.round((this.productUpdateRequestPg.price / factor + 0.2e-4) * 100) / 100;
	}
	set zeroVatPrice(value: number | undefined) {
		let vatValue = value;
		if (value != undefined) {
			let factor = 1 + (this.vatPercent || 0) / 100;
			vatValue = Math.round((value * factor + 0.2e-4) * 100) / 100;
		}
		this.productUpdateRequestPg.price = vatValue;
	}

	get cost() {
		// This is per StorageUnit, which needs to be calculated by our portion
		if (this.product.purchasePriceSu == undefined || this.product.storageUnitId == undefined || !this.portionSize) {
			return undefined;
		}
		let storageUnit = this.storageUnits[this.product.storageUnitId];
		const amount = this.portionSize.isStorageSize ? storageUnit.value : this.portionSize.amount;
		if (!storageUnit.value || amount == undefined) {
			/* Avoid dividing by 0 */
			return undefined;
		}
		return this.product.purchasePriceSu / storageUnit.value * amount;
	}

	get marginEuros() {
		if (this.cost == undefined || this.zeroVatPrice == undefined) {
			return undefined;
		}
		return this.zeroVatPrice - this.cost;
	}

	get marginPercent() {
		if (this.cost == undefined || !this.zeroVatPrice) {
			return undefined;
		}
		return (1 - (this.cost / this.zeroVatPrice)) * 100;
	}

	get portionSize() {
		if (this.productUpdateRequestPg.portionSizeId == undefined) {
			return undefined;
		}
		return this.portionSizes[this.productUpdateRequestPg.portionSizeId];
	}

	get salesChannel() {
		if (this.productUpdateRequestPg.salesChannelId == undefined) {
			return undefined;
		}
		return this.salesChannels[this.productUpdateRequestPg.salesChannelId];
	}

	get salesRestriction() {
		if (this.productUpdateRequestPg.salesRestrictionId == undefined) {
			return undefined;
		}
		return this.salesRestrictions[this.productUpdateRequestPg.salesRestrictionId];
	}

	get location() {
		if (this.productUpdateRequestPg.locationId == undefined) {
			return undefined;
		}
		return this.locations[this.productUpdateRequestPg.locationId];
	}

	get area() {
		if (this.productUpdateRequestPg.areaId == undefined) {
			return undefined;
		}
		return this.areas[this.productUpdateRequestPg.areaId];
	}

	get isHidden() {
		return this.productUpdateRequestPg.isHidden ? "X" : "";
	}

	// * Hox, the row values will update (e.g. vatPercent) only in save, not dynamically
	constructor(
		public product: ProductUpdateRequest,
		public vatPercent: number | undefined,
		public portionSizes: { [key: string]: PortionSize; },
		public salesChannels: { [key: string]: SalesChannel; },
		public salesRestrictions: { [key: string]: SalesRestriction; },
		public storageUnits: { [key: string]: StorageUnit; },
		public locations: { [key: string]: Location; },
		public areas: { [key: string]: Area; },
		public productUpdateRequestPg: ProductUpdateRequestPG,
		public manuallyAdded = false,
		public extraPortionSize?: PortionSize,
		public extraSalesChannel?: SalesChannel,
		public extraSalesRestriction?: SalesRestriction,
	) {
	}
}

@autoinject
export class PosProductEdit {
	private product: ProductUpdateRequest = {
		abbreviation: "",
		baseUnit: "PCS",
		doNotAllowComponentsToMerge: false,
		ean: [],
		locationId: undefined,
		name: "",
		useSlavePortion: false,
		useSlavePrice: false,
		openName: false,
		openPrice: false,
		pricingGroups: [],
		productCategoryId: "",
		productProduct: [],
		useSalesChannel: false,
		productType: "REGULAR",
		useChosenProductOnBill: false,
		useChosenProductOnOrderList: false,
		useChosenProductOnPos: false,
		warehousedOnly: false,
		//willBeInventoried: true,
		delete: false,
		type: 'CLIENT'
	};

	private productPricingGroups: UIPricingGroup[] = [];
	private selectedDefaultPricingGroupId?: string = undefined;
	private pricingGroupResponseList: PricingGroupResponse[] = [];
	private imageBlob?: string = undefined;
	private activeTab = "BASIC";
	private privilege?: PrivilegeItem;
  private typeAndName = "";
	private productCategoryList: ProductCategory[] = [];
	private productSubCategoryList: ProductSubCategory[] = [];
	private portionSizeList: PortionSize[] = [];
	private salesChannelList: SalesChannel[] = [];
	private salesRestrictionList: SalesRestriction[] = [];
	private pricingGroupList: PricingGroup[] = [];
	private extraProductCategory?: ProductCategory = undefined;
	private extraProductSubCategory?: ProductSubCategory = undefined;
	private extraStorageUnit?: StorageUnit = undefined;
	private locationList: Location[] = [];
	private areaList: Area[] = [];
	private storageUnitList: StorageUnit[] = [];
	private eanInput = "";
	private vatCategoryList: VatCategoryWithRows[] = [];
	modifyRowIndex?: number = undefined;
	modifyRowDebouncer?: number = undefined;

	locations: { [key: string]: Location } = {};
	areas: { [key: string]: Area } = {};
	portionSizes: { [key: string]: PortionSize; } = {};
	salesRestrictions: { [key: string]: SalesRestriction; } = {};
	salesChannels: { [key: string]: SalesChannel; } = {};
	storageUnits: { [key: string]: StorageUnit; } = {};

	constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private notify: Notify) {
	}

	async activate(params: { id?: string }) {
		this.privilege = getPrivilege();
		
		let [productCategoryList, productSubCategoryList, storageUnitList, portionSizeList, salesChannelList, salesRestrictionList] = await Promise.all([
			this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.storageUnitList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.portionSizeList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesChannelList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
		]);
		this.productCategoryList = productCategoryList.filter(x => !x.deleteTime);
		this.productSubCategoryList = productSubCategoryList.filter(x => !x.deleteTime);
		this.portionSizeList = portionSizeList.filter(x => !x.deleteTime);
		this.salesChannelList = salesChannelList.filter(x => !x.deleteTime);
		this.salesRestrictionList = salesRestrictionList.filter(x => !x.deleteTime);
		this.storageUnitList = storageUnitList.filter(x => !x.deleteTime);

		this.portionSizes = toHashStr(portionSizeList);
		this.salesChannels = toHashStr(salesChannelList);
		this.salesRestrictions = toHashStr(salesRestrictionList);
		this.storageUnits = toHashStr(storageUnitList);

		if (this.privilege?.id) {
			const [locationList, areaList] = await Promise.all([
				this.api.locationList({ id: this.privilege.id }),
				this.api.areaList({ id: this.privilege.id }),
			]);
			this.locationList = locationList.filter(x => !x.deleteTime);
			this.areaList = areaList.filter(x => !x.deleteTime);
			this.locations = toHashStr(this.locationList);
			this.areas = toHashStr(this.areaList);
		}

		if (params.id) {
			let [product, image] = await Promise.all([this.api.productByIdExtended({ id: params.id }), this.api.productImageById({ id: params.id })]);
			this.product = {
				...product.product,
				productType: product.product.type,
				delete: !!product.product.deleteTime,
				type: this.privilege?.type || 'CLIENT',
				//willBeInventoried: true, // ! FIXME
				pricingGroups: product.pricingGroups,
				productProduct: [],
			};
			this.privilege = privilegeItemFromElement(product.product);
			this.product.image = image?.imageData;
			this.imageBlob = image?.imageData && "data:" + image.imageMimeType + ";base64," + image.imageData;

			this.extraProductCategory = productCategoryList.find(x => x.id === product.product.productCategoryId);
			this.extraProductSubCategory = productSubCategoryList.find(x => x.id === product.product.productSubCategoryId);
			this.extraStorageUnit = storageUnitList.find(x => x.id === product.product.storageUnitId);

			let pricingGroups: UIPricingGroup[] = [];
			product.pricingGroups.filter(x => !x.deleteTime).forEach(pg => {
				let newProductUpdateRequestPg: ProductUpdateRequestPG = { ...pg };
				let newPg = new UIPricingGroup(
					this.product,
					this.vatPercent || 0,
					this.portionSizes,
					this.salesChannels,
					this.salesRestrictions,
					this.storageUnits,
					this.locations,
					this.areas,
					newProductUpdateRequestPg,
					false, /* manuallyAdded */
					portionSizeList.find(x => x.id === pg.portionSizeId),
					salesChannelList.find(x => x.id === pg.salesChannelId),
					salesRestrictionList.find(x => x.id === pg.salesRestrictionId),
				);
				pricingGroups.push(newPg);
			});
			pricingGroups.sort(this.sortPricingGroupRows);

			this.productPricingGroups = pricingGroups;
		}
    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);

		let pricingGroupResponseList = await this.api.pricingGroupListWithRows({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
		this.pricingGroupResponseList = pricingGroupResponseList.filter(x => !x.pricingGroup.deleteTime && !x.pricingGroup.supersededById);
		this.pricingGroupList = this.pricingGroupResponseList.map(x => x.pricingGroup);
	}

	sortPricingGroupRows(a: UIPricingGroup, b: UIPricingGroup): number {
		return (a.portionSize?.amount ?? Number.MAX_VALUE) - (b.portionSize?.amount ?? Number.MAX_VALUE)
			|| (b.location?.name ?? "").localeCompare(a.location?.name ?? "", 'fi', { sensitivity: "base" })
			|| (b.area?.name ?? "").localeCompare(a.area?.name ?? "", 'fi', { sensitivity: "base" })
			|| (a.salesChannel?.abbreviation ?? "").localeCompare(b.salesChannel?.abbreviation ?? "", 'fi', { sensitivity: "base" })
			|| (a.salesRestriction?.abbreviation ?? "").localeCompare(b.salesRestriction?.abbreviation ?? "", 'fi', { sensitivity: "base" })
			;
	}

	get productCategory() {
		if (this.product.productCategoryId && this.product.productCategoryId === this.extraProductCategory?.id) {
			return this.extraProductCategory;
		}
		return this.productCategoryList.find(x => this.product.productCategoryId == x.id);
	}

	vatPercentByVatCategoryIdAndDate(vatCategoryList: VatCategoryWithRows[], vatCategoryId: number, date?: Date): number {
		const vc = vatCategoryList.find(vc => vc.vatCategory.id === vatCategoryId);
		if (!vc) {
			return 0;
		}
		if (!date) {
			date = sameLocalDateAtMidnightUtc(new Date());
		}
		return vc.vatCategoryRows.find(vcr => date && vcr.startDate <= date)?.vatPercent || 0
	}

	get vatPercent() {
		const productCateogry = this.productCategory;
		if (!productCateogry) {
			return undefined;
		}
		return this.vatPercentByVatCategoryIdAndDate(this.vatCategoryList, productCateogry.vatCategoryId);
	}

	addPG() {
		let newProductUpdateRequestPg: ProductUpdateRequestPG = {
			isHidden: false,
		};
		let newPg = new UIPricingGroup(
			this.product,
			this.vatPercent,
			this.portionSizes,
			this.salesChannels,
			this.salesRestrictions,
			this.storageUnits,
			this.locations,
			this.areas,
			newProductUpdateRequestPg,
			true, /* manuallyAdded */
		);
		this.productPricingGroups.push(newPg);
		/* modifyRow is cleared by defaultProductPricingGroupObserver, set it after that */
		this.modifyRowDebouncer = this.productPricingGroups.indexOf(newPg); // generated from next()
	}

	removePG(ppg: UIPricingGroup) {
		const index = this.productPricingGroups.indexOf(ppg);
		if (index !== -1) {
			this.productPricingGroups.splice(index, 1);
		}
		this.modifyRowIndex = undefined;
	}

	modifyRowStart(i: number) {
		let newValue = i == this.modifyRowIndex ? undefined : i;
		this.modifyRowIndex = newValue;
	}

	@computedFrom("portionSizes", "pricingGroupResponseList", "product.productCategoryId", "productCategoryList", "salesChannels", "salesRestrictions", "selectedDefaultPricingGroupId", "sortPricingGroupRows", "storageUnits")
	get defaultProductPricingGroupObserver() {
		let effectivePricingGroup = this.pricingGroupResponseList.find(x => x.pricingGroup.id == this.selectedDefaultPricingGroupId);
		/* Clear empty rows, keep rows with price and belonging to selected/effective pricing group */
		this.productPricingGroups = (this.productPricingGroups.filter(pg =>
			pg.portionSize
			&& effectivePricingGroup?.rows.map(x => x.portionSizeId).includes(pg.portionSize.id) || pg.productUpdateRequestPg.price != undefined || pg.manuallyAdded)
		);

		/* Find missing effective pricing group rows and add them to list */
		let missingPricingGroupRows = effectivePricingGroup?.rows.filter(pg =>
			!this.productPricingGroups.find(ppg =>
				ppg.productUpdateRequestPg.portionSizeId == pg.portionSizeId
				&& ppg.productUpdateRequestPg.salesChannelId == pg.salesChannelId
				&& ppg.productUpdateRequestPg.salesRestrictionId == pg.salesRestrictionId
				&& ppg.productUpdateRequestPg.locationId == pg.locationId
				&& ppg.productUpdateRequestPg.areaId == pg.areaId
			));

		const productCategory = this.productCategory;

		if (productCategory) {
			missingPricingGroupRows
				?.forEach(pg => {
					let newProductUpdateRequestPg: ProductUpdateRequestPG = {
						portionSizeId: pg.portionSizeId,
						salesChannelId: pg.salesChannelId,
						salesRestrictionId: pg.salesRestrictionId,
						locationId: pg.locationId,
						areaId: pg.areaId,
						isHidden: pg.isHidden,
					};
					const newPg = new UIPricingGroup(
						this.product,
						this.vatPercent,
						this.portionSizes,
						this.salesChannels,
						this.salesRestrictions,
						this.storageUnits,
						this.locations,
						this.areas,
						newProductUpdateRequestPg,
					);

					this.productPricingGroups.push(newPg);
				});
			this.productPricingGroups.sort(this.sortPricingGroupRows);
		}

		this.modifyRowDebouncer = undefined;

		return "";
	}

	_buildRequest(): ProductUpdateRequest {
		let obj = { ...this.product };
		const businessGroupSpecificPG = this.productPricingGroups.filter(ppg => !ppg.productUpdateRequestPg.clientId);
		obj.pricingGroups = businessGroupSpecificPG.map(ppg => ppg.productUpdateRequestPg);
		return obj;
	}

	async save(deleted: boolean) {
		let obj = this._buildRequest();
		await this.api.productUpdate({...obj,
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			delete: deleted,
		});
		this.router.navigateBack();
	}

	@computedFrom("privilege.type")
	get showLocationAndArea() {
		return this.privilege?.type === 'CLIENT';
	}

	/* Automatically fill in abbreviation. If the name is 1 char longer or shorter and shares a common root, then overwrite abbreviation. */
	@computedFrom("product.name")
	get nameObserver() {
		let name = this.product.name || "";
		let abbreviation = this.product.abbreviation || "";

		if (Math.abs(name.length - abbreviation.length) <= 1 && name.substring(0, Math.min(name.length, abbreviation.length)) === abbreviation.substring(0, Math.min(name.length, abbreviation.length))) {
			this.product.abbreviation = name.substring(0, 40); // generated from next()
		}
		return "";
	}

	selectTab(tabName: string) {
		// check that all required fields are filled in before allowing to change the tab
		if (document.querySelector("form")?.reportValidity()) {
			this.activeTab = tabName;
		}
	}

	clearImage() {
		this.product.image = undefined;
		this.imageBlob = undefined;
	}

	async productImageOnLoadHandler(event: Event) {
		const inputElement = event.target;
		const files = (<HTMLInputElement>inputElement).files;
		if (!files) {
			return;
		}
		const file = files[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();
		reader.onload = async () => {
			const res = reader.result;
			if (typeof res === "string") {
				const imageData = (reader.result as string).split(",", 2)[1];
				let imageIsOk = await this.api.imageTest({ image: imageData });
				if (!imageIsOk) {
					this.product.image = undefined;
					this.notify.info("admin.imageTestNotOk", {});
					return;
				}
				this.imageBlob = imageData && "data:" + file.type + ";base64," + imageData;
				this.product.image = imageData;
			}
		};
		await reader.readAsDataURL(file);
	}

	deleteEan(idx: number) {
		this.product.ean.splice(idx, 1);
	}

	addEan() {
		if (this.eanInput.length) {
			this.product.ean.push(this.eanInput);
		}
		this.eanInput = "";
	}

	@computedFrom('product.baseUnit', 'storageUnitList.length')
	get storageUnitListDynamic() {
		let baseUnit = this.product.baseUnit;
		return this.storageUnitList.filter(su => !baseUnit || su.baseUnit === baseUnit);
	}

	@computedFrom('product.productCategoryId', 'productSubCategoryList.length')
	get productSubCategoryListDynamic() {
		return this.productSubCategoryList.filter(pc => this.product.productCategoryId != undefined && pc.productCategoryId === this.product.productCategoryId);
	}

	@computedFrom('locationList.length', 'privilege.type')
	get canModifyLocation() {
		return this.privilege?.type === "CLIENT" && this.locationList.length > 0;
	}

	get defaultPricingGroupList(): PricingGroup[] {
		return this.pricingGroupList.filter(x => x.isDefault);
	}

	areaListDynamic(locationId: string) {
		return this.areaList.filter(x => x.locationId === locationId);
	}

	@computedFrom("product.baseUnit")
	get baseUnitObserver() {
		let storageUnit = this.storageUnitList.find(su => su.baseUnit === this.product.baseUnit);
		if (!storageUnit) {
			this.product.storageUnitId = undefined;
		}
		return "";
	}

	@computedFrom("product.productCategoryId", "product.productSubCategoryId")
	get categoryObserver() {
		let productCategory = this.productCategory;
		const productSubCategory = this.productSubCategoryList.find(x => x.id === this.product.productSubCategoryId);
		if (this.product.productSubCategoryId && productSubCategory?.productCategoryId !== productCategory?.id) {
			this.product.productSubCategoryId = undefined; // generated from next()
			this.extraProductSubCategory = undefined;
		}
		if (productCategory) {
			this.product.baseUnit = productSubCategory?.defaultBaseUnit || productCategory.defaultBaseUnit;
			this.product.storageUnitId = productSubCategory?.defaultStorageUnitId || productCategory.defaultStorageUnitId;
			//this.refreshSelectedDefaultPricingGroupId();
		}

		// Default to most specific pricing group
		const subcategorySpecificPG = this.defaultPricingGroupList.find(pg => pg.productCategoryId == this.product.productCategoryId && pg.productSubCategoryId == this.product.productSubCategoryId);
		const mainCategorySpecificPG = this.defaultPricingGroupList.find(pg => pg.productCategoryId == this.product.productCategoryId && pg.productSubCategoryId == undefined);
		const genericPG = this.defaultPricingGroupList.find(pg => pg.productCategoryId == undefined && pg.productSubCategoryId == undefined);

		const defaultPricingGroup = subcategorySpecificPG ?? mainCategorySpecificPG ?? genericPG;
		this.selectedDefaultPricingGroupId = defaultPricingGroup?.id;
		return "";
	}

	/* modifyRowDebouncer is used for modifying modifyRow without annoying flickering of UI elements */
	@computedFrom("modifyRowDebouncer")
	get modifyRowObserver() {
		this.modifyRowIndex = this.modifyRowDebouncer;
		return "";
	}

}
